html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.App .logo img {
  margin-top: 10px;
}

div:focus {
  outline:none
}

video:focus {
  outline:none
}

html {
  box-sizing: border-box;
}

*, *:before, *:after { box-sizing: inherit; }

body {
  margin:0;
  padding:0;
  width: 100%;
  height: 100%;
}
.App {
  text-align: center;
  height:100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 770px) {
  .App section {
    margin-top: 0px;
  }
  .App > div:nth-child(3) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
